import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
  contactname: '',
  contactemail: '',
  contactbody: '',
}

const inputStyle={
  color: "#000",
}

const inputStyleName={
  ...inputStyle,
  margin: "0px 0px 0px 50px",
  width: "300px",
}

const inputStyleEmail={
  ...inputStyle,
  margin: "10px 0px 10px 25px",
  width: "300px",
}

const inputStyleBody={
  ...inputStyle,
  margin: "30px 0px 0px -105px",
  width: "80%",
  height: "100px",
}

const buttonStye={
  ...inputStyle,
  margin:"10px -15px",  
}

export const Contact = (props) => {
  const [{ name, email, message, contactname, contactemail, contactbody }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value , id} = e.target
    if(id === "contactname")
    {
       setState((prevState) => ({ ...prevState, ["contactname"]: value }))
    }
    else if(id === "contactemail")
    {
       setState((prevState) => ({ ...prevState, ["contactemail"]: value }))
    }
    else if(id === "contactbody")
    {
       setState((prevState) => ({ ...prevState, ["contactbody"]: value }))
    }
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(contactname, contactemail, contactbody)
    const sendData = {
     "name": contactname,
     "email": contactemail,
     "message": contactbody,
    }
    console.log(sendData)
    fetch('https://modular.run/api/contact_us/?clientKey=85de3f75-e49b-4b92-9650-ff60e621329f',
          {
              method:'POST',
              headers:{
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(sendData)
          }
          )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className="col-md-7 col-md-offset-1">
             <h3>Email Us</h3>
             <div class="row">
                <label> Name:
                   <input type="text" style={inputStyleName} id="contactname" value={contactname} onChange={handleChange}/>
                </label>
             </div>
             <div class="row">
                 <label> Your Email:
                    <input type="text" style={inputStyleEmail} id="contactemail" value={contactemail} onChange={handleChange}/>
                 </label>
             </div>
             <div class="row">
                 <label> Tell Us Your Need: </label>
                 <textarea type="text" style={inputStyleBody} id="contactbody" value={contactbody} onChange={handleChange}/>
             </div>
             <button type="button" style={buttonStye} onClick={handleSubmit}>Send</button>
          </div>
          <div className='col-md-12'>
	  {/*<div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  )
}
